import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import {
	IDateFilters,
	ILoginReportFilter,
	ILoginReportResponse,
	IStudentAccountInfo,
	IStudentPaymentHistory,
	IUserCourseCompletionDetailForAdmin,
	IUserReport,
	User,
	UserSubscriptionType
} from "@remar/shared/dist/models";

import { IUserCustomTestResponse, IUserCustomTestsResponse } from "@remar/shared/dist/services/userCustomTests/dto";

import { StudentCountByCourseResult, UserLoginDto, UserLoginResponseDto } from "./dto";

import { URLS } from "../services.constants";
import { StudentsAddCATDto } from "../students";

export class UsersService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	login(data: UserLoginDto): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	logout(): Promise<void> {
		return request(`${this.baseUrl}/logout`, "POST", {}, { credentials: "include" }, false, true) as Promise<void>;
	}

	whoami(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/whoami`, "GET") as Promise<{ user: User }>;
	}

	getAccount(id: string): Promise<{ user: IStudentAccountInfo }> {
		return request(`${this.baseUrl}/${id}/account`, "GET") as Promise<{ user: IStudentAccountInfo }>;
	}

	getSubscription(id: string): Promise<{ user: UserSubscriptionType }> {
		return request(`${this.baseUrl}/${id}/subscription`, "GET") as Promise<{ user: UserSubscriptionType }>;
	}

	getPaymentHistory(id: string, query: { page?: number }): Promise<IStudentPaymentHistory> {
		return request(
			`${this.baseUrl}/${id}/payment-history${this.serializeQuery(query as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IStudentPaymentHistory>;
	}

	getStudentReport(userId: string, data: IDateFilters): Promise<IUserReport> {
		return request(
			`${this.baseUrl}/${userId}/report${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IUserReport>;
	}

	getStudentLoginReport(userId: string, data: ILoginReportFilter): Promise<ILoginReportResponse> {
		return request(
			`${this.baseUrl}/${userId}/login-chart${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<ILoginReportResponse>;
	}

	getStudentTests(userId: string, data: BaseFindDto): Promise<IUserCustomTestsResponse> {
		return request(
			`${this.baseUrl}/${userId}/custom-tests${this.serializeQuery(data as Record<string, unknown>, {
				isTopLevel: true
			})}`,
			"GET"
		) as Promise<IUserCustomTestsResponse>;
	}

	getStudentTest(userId: string, testId: number): Promise<IUserCustomTestResponse> {
		return request(`${this.baseUrl}/${userId}/custom-tests/${testId}`, "GET") as Promise<IUserCustomTestResponse>;
	}

	getStudentCourseCompletion(userId: number, courseId: number): Promise<IUserCourseCompletionDetailForAdmin> {
		return request(
			`${this.baseUrl}/${userId}/course-details/${courseId}`,
			"GET"
		) as Promise<IUserCourseCompletionDetailForAdmin>;
	}

	getStudentCATQuota(userId: number, courseId: number): Promise<StudentsAddCATDto> {
		return request(
			`${this.baseUrl}/get-cat-quota-count-user/${userId}/${courseId}`,
			"GET"
		) as Promise<StudentsAddCATDto>;
	}

	addStudentCATQuota(userId: number, quantity: number): Promise<void> {
		return request(`${this.baseUrl}/add-cat-quota-to-user`, "POST", { userId, quantity }) as Promise<void>;
	}

	getTotalStudentsByCourse(): Promise<Array<StudentCountByCourseResult>> {
		return request(`${this.baseUrl}/get-total-students-by-course`, "GET") as Promise<Array<StudentCountByCourseResult>>;
	}

	resetPassword(userId: number): Promise<{ url: string }> {
		return request(`${this.baseUrl}/${userId}/one-time-login-link`, "POST") as Promise<{ url: string }>;
	}

	resetCourseProgress(userId: number): Promise<unknown> {
		return request(`${this.baseUrl}/${userId}/reset-user-progress`, "POST") as Promise<unknown>;
	}
}

export const usersService = new UsersService(URLS.USERS);
