import React, { useEffect, useMemo, useState } from "react";

import { Box, Breadcrumbs, Button, Card, CircularProgress, Divider, Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { MAX_COURSE_ADDONS } from "@remar/shared/dist/constants";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import CloseIcon from "@remar/shared/dist/ui/Icons/CloseIcon";
import copyPasteOnlyInput from "@remar/shared/dist/utils/copyPasteOnlyInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import { PAYMENT_CONSTANTS } from "store/features/CourseAddEdit/courseAddEdit.constants";
import {
	addCourseFeature,
	createAddonItem,
	createInitialOffering,
	deleteCourseFeature,
	fetchAppleProductData,
	fetchData,
	fetchShipStationData,
	fetchStripeData,
	getFullState,
	removeAddonItem,
	removeInitialOffering,
	resetState,
	saveForm,
	setAsDefault,
	setDigitalAssetName,
	setStateValue,
	toggleDigitalAsset,
	// toggleIsQuestionBankOnly,
	updateCourseFeatures,
	updatePublishedState,
	validateForm
} from "store/features/CourseAddEdit/courseAddEdit.slice";
import { CourseAddEditState } from "store/features/CourseAddEdit/models";
import { _emit } from "store/features/notifications/notifications.slice";
import { genericService } from "store/services";

import { routes } from "core/constants";

import theme from "theme/default";

import {
	CourseFeatures,
	CourseFeaturesSubHeading,
	CourseItemTitleContainer,
	DeleteFeature,
	DragFeatureContainer,
	FeatureText,
	MarginTopOneContainer,
	MarginTopTwoContainer,
	MarginTopZeroContainer,
	useCourseAddEditStyles
} from "./courseAddEdit.styles";

import { useTestOnlyQuestionStyles } from "../CreateLesson/TestOnlyQuestions/testOnlyQuestions.styles";

const CourseAddEdit = () => {
	const classes = { ...useCourseAddEditStyles(), ...useTestOnlyQuestionStyles() };
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<{ id?: string }>();
	const idFromParams = parseInt(params.id || "0", 10);
	const {
		courseForm,
		courseFeaturesForm,
		data,
		externalIntegrationData,
		isLoading,
		newCourseId,
		publishIsLoading,
		saveIsLoading,
		userSubscriptionTypesForm,
		data: {
			data: { marketingPoints }
		}
	} = useSelector(getFullState) as CourseAddEditState;
	const [isAddFeature, setIsAddFeature] = useState(false);
	const { id, externalIntegrationData: externalIntegration } = data;
	const stripLinkAdded = !!externalIntegration?.initial?.stripePlanId;
	const { inputs: courseFormInputs, valid: courseFormValid } = courseForm;
	const { inputs: courseFeaturesFormInputs } = courseFeaturesForm;
	const { isActive: isActiveInput } = courseFormInputs;
	const { value: isActive } = isActiveInput;
	const { inputs: ustFormInputs, valid: ustFormValid } = userSubscriptionTypesForm;
	const {
		initial,
		recurring,
		recurringQuestionBankOnly,
		hasQuestionBankOnlyConfiguration,
		hasTrial: hasTrialInput,
		additionalInitial
	} = ustFormInputs;
	const addons = initial.addons!;
	const {
		shipStationItem: initialShipStationItem,
		digitalAssetItem: initialDigitalAssetItem,
		stripeItem: initialStripeItem,
		isDigital: isDigitalInput,
		isDefault: isDefaultInput,
		// isQuestionBankOnly: isInitialQuestionBankOnly,
		appleProductItem: initialAppleProductItem
	} = initial;
	const initialIsLoading = initialStripeItem.isLoading.value;
	const { stripeItem: recurringStripeItem } = recurring;
	const recurringIsLoading = recurringStripeItem.isLoading.value;
	const { stripeItem: recurringQuestionBankOnlyStripeItem } = recurringQuestionBankOnly;
	const recurringQuestionBankOnlyIsLoading = recurringQuestionBankOnlyStripeItem.isLoading.value;
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	let deletedAddonsCount = 0;
	let marketingPointId = 0;

	useEffect(() => {
		if ((!id && idFromParams) || (id && idFromParams && id !== idFromParams)) {
			dispatch(fetchData(idFromParams));
		} else if (id && !idFromParams) {
			dispatch(resetState());
		}
	}, [location.pathname, idFromParams, id, dispatch]);
	useEffect(() => {
		if (newCourseId) {
			history.push(`${routes.courseAddEdit.getPath()}/${newCourseId}`);
		}
	}, [newCourseId]);

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	const memoizedMarketingPoints = useMemo(
		() =>
			marketingPoints.map(item => {
				return { id: marketingPointId++, feature: item };
			}),
		[marketingPoints]
	);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}
		const items = reorder(marketingPoints, result.source.index, result.destination.index) as {
			id: number;
			feature: string;
		}[];
		dispatch(updateCourseFeatures(items));
	};

	const externalIntegrationDataRowLoader = (
		<CircularProgress size="1.4rem" color="primary" thickness={5} variant="indeterminate" />
	);

	if (isLoading) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	return (
		<Box display="flex" justifyContent="space-between" alignItems="center">
			<Box>
				<Typography className={classes.title}>{id ? "Edit Course" : "Create New Course"}</Typography>
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to={`${routes.course.getPath()}`}>
							<Typography className={classes.breadcrumb}>Courses</Typography>
						</Link>
						<Typography className={classes.breadcrumbActive}>{id ? "Edit Course" : "Create New Course"}</Typography>
					</Breadcrumbs>
					<Box display="flex" flexDirection="row">
						<Box>
							<Button
								variant="contained"
								className={classes.cancelButton}
								onClick={() => history.push(routes.course.getPath())}
							>
								Back
							</Button>
						</Box>
						{id ? (
							<Box ml={2}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => dispatch(updatePublishedState())}
									disabled={(!isActive && (!courseFormValid || !ustFormValid)) || publishIsLoading}
								>
									{isActive ? "Unpublish" : "Publish"}
								</Button>
							</Box>
						) : null}
					</Box>
				</Box>
				<Card>
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Typography>Course Details</Typography>
						<Box display="flex" flexDirection="row">
							<Box>
								<Button
									variant="contained"
									color="primary"
									onClick={() => dispatch(saveForm("courseForm"))}
									disabled={saveIsLoading}
								>
									Save
								</Button>
							</Box>
						</Box>
					</Box>
					<Box display="flex" flexDirection="row" mt={7} mb={2}>
						<Box display="flex" flexDirection="column" mr={10}>
							<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
								<CourseItemTitleContainer>
									<Typography align="right">Title</Typography>
								</CourseItemTitleContainer>
								<CustomInput
									width={400}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: courseFormInputs.name }}
								/>
							</Box>{" "}
							<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
								<CourseItemTitleContainer>
									<Typography align="right">Description</Typography>
								</CourseItemTitleContainer>
								<CustomInput
									width={400}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: courseFormInputs.description }}
								/>
							</Box>
							<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
								<CourseItemTitleContainer>
									<Typography align="right">
										{courseFormInputs.calculateTimeLengthAutomatically.value
											? "Manual Length Component"
											: "Total Length"}
									</Typography>
								</CourseItemTitleContainer>
								<CustomInput
									width={150}
									mr={2}
									theme={theme}
									InputProps={{
										disabled: courseFormInputs.calculateTimeLengthAutomatically.value
									}}
									options={{ ...defaultCustomInputOptions, inputData: courseFormInputs.timeLengthHours }}
								/>
								<CustomInput
									width={150}
									theme={theme}
									InputProps={{
										disabled: courseFormInputs.calculateTimeLengthAutomatically.value
									}}
									options={{ ...defaultCustomInputOptions, inputData: courseFormInputs.timeLengthMinutes }}
								/>
							</Box>
							<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
								<CourseItemTitleContainer />
								<CustomInput
									inputProps={{
										onChange: e => {
											const totalLength = (e.target as unknown as { checked: boolean }).checked
												? data.manualTimeLengthComponent
												: data.totalTimeLengthInMinutes;
											dispatch(
												setStateValue({
													key: `${courseFormInputs.timeLengthHours.statePath}.value`,
													value: Math.floor(totalLength / 60)
												})
											);
											dispatch(
												setStateValue({
													key: `${courseFormInputs.timeLengthMinutes.statePath}.value`,
													value: Math.floor(totalLength % 60)
												})
											);
										}
									}}
									theme={theme}
									options={{
										...defaultCustomInputOptions,
										inputData: courseFormInputs.calculateTimeLengthAutomatically
									}}
								/>
							</Box>
							<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
								<CourseItemTitleContainer />
								<CustomInput
									theme={theme}
									options={{
										...defaultCustomInputOptions,
										inputData: courseFormInputs.freeShipping
									}}
								/>
							</Box>
						</Box>
						<Box display="flex" flexDirection="column" mr={2}>
							<CustomInput
								width={246}
								height={246}
								theme={theme}
								inputProps={{
									fileUploaderDescription: (
										<>
											Drag and Drop File Here or Browse to Choose a File <br />
											<br />
											Recommended Size 1440x1000
										</>
									)
								}}
								options={{
									...defaultCustomInputOptions,
									genericApiService: genericService,
									inputData: courseFormInputs.mainImageKey
								}}
							/>
						</Box>
					</Box>
				</Card>
				<Box mt={3}></Box>
				{id ? (
					<>
						<Card>
							<Box display="flex" flexDirection="row" justifyContent="space-between">
								<Typography>Stripe Connect</Typography>
								<Box>
									<Button
										variant="contained"
										color="primary"
										className={classes.addInitialOfferingButton}
										onClick={() => dispatch(createInitialOffering())}
										disabled={saveIsLoading}
									>
										Add Initial Offering
									</Button>
									<Button
										variant="contained"
										color="primary"
										onClick={() => dispatch(saveForm("userSubscriptionTypesForm"))}
										disabled={saveIsLoading || !userSubscriptionTypesForm.valid}
									>
										Save
									</Button>
								</Box>
							</Box>
							<Box display="flex" flexDirection="row" mt={4} mb={4}>
								<Box display="flex" flexDirection="column" justifyContent="center" mr={10}>
									<Box display="flex" flexDirection="row" alignItems="center">
										<CourseItemTitleContainer>
											<Typography align="right">Stripe Link (Initial)</Typography>
										</CourseItemTitleContainer>
										<CustomInput
											width={400}
											theme={theme}
											inputProps={{
												onChange: e => dispatch(fetchStripeData(e.target.value, PAYMENT_CONSTANTS.INITIAL)),
												onKeyPress: copyPasteOnlyInput
											}}
											options={{ ...defaultCustomInputOptions, inputData: initialStripeItem.planId }}
										/>
									</Box>
									{!isDigitalInput.value && (
										<MarginTopZeroContainer>
											<CourseItemTitleContainer>
												<Typography align="right">Acutrack Product SKU (Initial)</Typography>
											</CourseItemTitleContainer>
											<CustomInput
												width={400}
												theme={theme}
												inputProps={{
													onChange: e => dispatch(fetchShipStationData(e.target.value, PAYMENT_CONSTANTS.INITIAL)),
													onKeyPress: copyPasteOnlyInput
												}}
												options={{ ...defaultCustomInputOptions, inputData: initialShipStationItem.clientSKU }}
											/>
										</MarginTopZeroContainer>
									)}
									<Box display="flex" flexDirection="row" alignItems="center" mt={2}>
										<CourseItemTitleContainer>
											<Typography align="right">Apple Product SKU</Typography>
										</CourseItemTitleContainer>
										<CustomInput
											width={400}
											theme={theme}
											inputProps={{
												onChange: e => dispatch(fetchAppleProductData(e.target.value, PAYMENT_CONSTANTS.INITIAL)),
												onKeyPress: copyPasteOnlyInput
											}}
											options={{ ...defaultCustomInputOptions, inputData: initialAppleProductItem.productKey }}
										/>
									</Box>
									<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
										<CourseItemTitleContainer>
											<Typography align="right">Stripe Link (Recurring)</Typography>
										</CourseItemTitleContainer>
										<CustomInput
											width={400}
											theme={theme}
											inputProps={{
												onChange: e => dispatch(fetchStripeData(e.target.value, PAYMENT_CONSTANTS.RECURRING)),
												onKeyPress: copyPasteOnlyInput
											}}
											options={{ ...defaultCustomInputOptions, inputData: recurringStripeItem.planId }}
										/>
									</Box>
									{hasQuestionBankOnlyConfiguration.value && (
										<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
											<CourseItemTitleContainer>
												<Typography align="right">Stripe Link Question Bank Only (Recurring)</Typography>
											</CourseItemTitleContainer>
											<CustomInput
												width={400}
												theme={theme}
												inputProps={{
													onChange: e =>
														dispatch(fetchStripeData(e.target.value, PAYMENT_CONSTANTS.RECURRING_QUESTION_BANK_ONLY)),
													onKeyPress: copyPasteOnlyInput
												}}
												options={{
													...defaultCustomInputOptions,
													inputData: recurringQuestionBankOnlyStripeItem.planId
												}}
											/>
										</Box>
									)}
									<Box display="flex" flexDirection="row" alignItems="center" mt={3}>
										<CourseItemTitleContainer />
										<CustomInput
											theme={theme}
											options={{
												...defaultCustomInputOptions,
												inputData: hasTrialInput
											}}
										/>
									</Box>
									<MarginTopOneContainer>
										<CourseItemTitleContainer />
										<CustomInput
											theme={theme}
											onChange={e => {
												const isDigital = e.target.checked;
												dispatch(toggleDigitalAsset(isDigital, PAYMENT_CONSTANTS.INITIAL));
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: isDigitalInput
											}}
										/>
									</MarginTopOneContainer>
									<MarginTopOneContainer>
										<CourseItemTitleContainer />
										<CustomInput
											theme={theme}
											onChange={({ target: { checked } }) => {
												dispatch(setAsDefault({ innerPath: PAYMENT_CONSTANTS.INITIAL, value: checked }));
											}}
											options={{
												...defaultCustomInputOptions,
												inputData: isDefaultInput
											}}
										/>
									</MarginTopOneContainer>
									{isDigitalInput.value && (
										<MarginTopOneContainer>
											<CourseItemTitleContainer />
											<CustomInput
												height={120}
												theme={theme}
												onChange={e => {
													const files = e.target.files;
													if (files && files[0]) {
														dispatch(setDigitalAssetName(files[0], PAYMENT_CONSTANTS.INITIAL));
													}
												}}
												options={{
													...defaultCustomInputOptions,
													genericApiService: genericService,
													inputData: initialDigitalAssetItem.productKey
												}}
											/>
										</MarginTopOneContainer>
									)}
									{/*<Box display="flex" flexDirection="row" alignItems="center">*/}
									{/*	<CourseItemTitleContainer />*/}
									{/*	<CustomInput*/}
									{/*		theme={theme}*/}
									{/*		onChange={() => {*/}
									{/*			dispatch(toggleIsQuestionBankOnly());*/}
									{/*		}}*/}
									{/*		options={{*/}
									{/*			...defaultCustomInputOptions,*/}
									{/*			inputData: isInitialQuestionBankOnly*/}
									{/*		}}*/}
									{/*	/>*/}
									{/*</Box>*/}
								</Box>
								<Box className={classes.stripeSummaryBox} display="flex" flexDirection="column" mr={2} width={350}>
									<Box className="row" display="flex" flexDirection="row">
										<Typography className="row-title">Product Name</Typography>
										{initialIsLoading || recurringIsLoading || recurringQuestionBankOnlyIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">{externalIntegrationData.planName || "-"}</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Initial Price</Typography>
										{initialIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">
												{initialStripeItem.price.value
													? `${externalIntegrationData.currency} ${initialStripeItem.price.value.toFixed(2)}`
													: "-"}
											</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Initial Length</Typography>
										{initialIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">{initialStripeItem.duration.value || "-"}</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Initial Acutrack Product Name</Typography>
										{initialIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">{initialShipStationItem.name.value || "-"}</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Apple Product Name</Typography>
										{initialAppleProductItem.isLoading.value ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">
												{`${initialAppleProductItem.name.value} - ${initialAppleProductItem.duration.value}` || "-"}
											</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Recurring Charge</Typography>
										{recurringIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">
												{recurringStripeItem.price.value
													? `${externalIntegrationData.currency} ${recurringStripeItem.price.value.toFixed(2)}`
													: "-"}
											</Typography>
										)}
									</Box>
									<Box className="row" display="flex" flexDirection="row" mt={3}>
										<Typography className="row-title">Recurring Interval</Typography>
										{recurringIsLoading ? (
											externalIntegrationDataRowLoader
										) : (
											<Typography className="row-content">{recurringStripeItem.duration.value || "-"}</Typography>
										)}
									</Box>
									{hasQuestionBankOnlyConfiguration.value && (
										<>
											<Box className="row" display="flex" flexDirection="row" mt={3}>
												<Typography className="row-title">Recurring Charge (Question Bank Only)</Typography>
												{recurringQuestionBankOnlyIsLoading ? (
													externalIntegrationDataRowLoader
												) : (
													<Typography className="row-content">
														{recurringQuestionBankOnlyStripeItem.price.value
															? `${
																	externalIntegrationData.currency
															  } ${recurringQuestionBankOnlyStripeItem.price.value.toFixed(2)}`
															: "-"}
													</Typography>
												)}
											</Box>
											<Box className="row" display="flex" flexDirection="row" mt={3}>
												<Typography className="row-title">Recurring Interval</Typography>
												{recurringQuestionBankOnlyIsLoading ? (
													externalIntegrationDataRowLoader
												) : (
													<Typography className="row-content">
														{recurringQuestionBankOnlyStripeItem.duration.value || "-"}
													</Typography>
												)}
											</Box>
										</>
									)}
								</Box>
							</Box>
							{additionalInitial.map((item, itemIndex) => {
								const currentStripeItem = item.stripeItem;
								const isLoading = currentStripeItem.isLoading.value;
								if (currentStripeItem.deleted.value) {
									return null;
								}
								return (
									<>
										<Divider />
										<Box
											display="flex"
											flexDirection="row"
											mt={4}
											mb={4}
											key={`courseAddEdit-additionalOffering-${itemIndex}`}
										>
											<Box display="flex" flexDirection="column" justifyContent="center" mr={10}>
												<Box display="flex" flexDirection="row" alignItems="center">
													<CourseItemTitleContainer>
														<Typography align="right">Stripe Link (Initial)</Typography>
													</CourseItemTitleContainer>
													<CustomInput
														width={400}
														theme={theme}
														inputProps={{
															onChange: e =>
																dispatch(fetchStripeData(e.target.value, `additionalInitial.${itemIndex}`)),
															onKeyPress: copyPasteOnlyInput
														}}
														options={{ ...defaultCustomInputOptions, inputData: currentStripeItem.planId }}
													/>
												</Box>
												<MarginTopOneContainer>
													<CourseItemTitleContainer />
													<CustomInput
														theme={theme}
														onChange={({ target: { checked } }) => {
															dispatch(setAsDefault({ innerPath: `additionalInitial.${itemIndex}`, value: checked }));
														}}
														options={{
															...defaultCustomInputOptions,
															inputData: item.isDefault
														}}
													/>
												</MarginTopOneContainer>
												{/*<Box display="flex" flexDirection="row" alignItems="center">*/}
												{/*	<CourseItemTitleContainer />*/}
												{/*	<CustomInput*/}
												{/*		theme={theme}*/}
												{/*		onChange={() => {*/}
												{/*			dispatch(toggleIsQuestionBankOnly());*/}
												{/*		}}*/}
												{/*		options={{*/}
												{/*			...defaultCustomInputOptions,*/}
												{/*			inputData: item.isQuestionBankOnly*/}
												{/*		}}*/}
												{/*	/>*/}
												{/*</Box>*/}
												<MarginTopOneContainer>
													<CourseItemTitleContainer />
													<Button
														variant="contained"
														color="secondary"
														className={classes.deleteInitialOfferingButton}
														onClick={() => dispatch(removeInitialOffering({ index: itemIndex }))}
														disabled={saveIsLoading}
													>
														Delete Initial Offering
													</Button>
												</MarginTopOneContainer>
											</Box>
											<Box
												className={classes.stripeSummaryBox}
												display="flex"
												flexDirection="column"
												mr={2}
												width={350}
											>
												<Box className="row" display="flex" flexDirection="row">
													<Typography className="row-title">Product Name</Typography>
													{initialIsLoading || recurringIsLoading || recurringQuestionBankOnlyIsLoading || isLoading ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">{externalIntegrationData.planName || "-"}</Typography>
													)}
												</Box>
												<Box className="row" display="flex" flexDirection="row" mt={3}>
													<Typography className="row-title">Initial Price</Typography>
													{isLoading ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">
															{currentStripeItem.price.value
																? `${externalIntegrationData.currency} ${currentStripeItem.price.value.toFixed(2)}`
																: "-"}
														</Typography>
													)}
												</Box>
												<Box className="row" display="flex" flexDirection="row" mt={3}>
													<Typography className="row-title">Initial Length</Typography>
													{initialIsLoading ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">{currentStripeItem.duration.value || "-"}</Typography>
													)}
												</Box>
											</Box>
										</Box>
									</>
								);
							})}
							<Typography>Stripe Addons</Typography>
							{addons.map((addonItem, addonItemIndex) => {
								const {
									digitalAssetItem: addonDigitalAssetItem,
									shipStationItem: addonShipStationItem,
									stripeItem: addonStripeItem,
									isDigital: addonIsDigitalInput,
									isDefault: addonIsDefaultlInput,
									mainImageKey,
									mainImageUrl
								} = addonItem;
								if (addonStripeItem.deleted.value) {
									deletedAddonsCount++;
									return null;
								}

								return (
									<Box
										display="flex"
										flexDirection="column"
										alignItems="center"
										mt={4}
										key={`courseAddEdit-addonItem-${addonItemIndex}`}
									>
										<Box display="flex" flexDirection="row" alignItems="center" mt={4}>
											<Box display="flex" flexDirection="column" mr={3}>
												<Box display="flex" flexDirection="row" alignItems="center">
													<CourseItemTitleContainer>
														<Typography align="right">
															{addonItemIndex - deletedAddonsCount + 1}. Stripe Link
														</Typography>
													</CourseItemTitleContainer>
													<CustomInput
														width={400}
														theme={theme}
														inputProps={{
															onChange: e =>
																dispatch(fetchStripeData(e.target.value, `initial.addons.${addonItemIndex}`)),
															onKeyPress: copyPasteOnlyInput
														}}
														options={{ ...defaultCustomInputOptions, inputData: addonStripeItem.planId }}
													/>
													<Box ml={2}>
														<CloseIcon
															color={"#586179"}
															size={"30px"}
															onClick={() =>
																dispatch(
																	removeAddonItem({
																		addonParent: PAYMENT_CONSTANTS.INITIAL,
																		addonIndex: addonItemIndex
																	})
																)
															}
														/>
													</Box>
												</Box>
												{!addonIsDigitalInput.value && (
													<MarginTopTwoContainer>
														<CourseItemTitleContainer>
															<Typography align="right">Acutrack Product SKU</Typography>
														</CourseItemTitleContainer>
														<CustomInput
															width={400}
															theme={theme}
															inputProps={{
																onChange: e => {
																	dispatch(fetchShipStationData(e.target.value, `initial.addons.${addonItemIndex}`));
																},
																onKeyPress: copyPasteOnlyInput
															}}
															options={{ ...defaultCustomInputOptions, inputData: addonShipStationItem.clientSKU }}
														/>
													</MarginTopTwoContainer>
												)}
												<MarginTopOneContainer>
													<CourseItemTitleContainer />
													<CustomInput
														theme={theme}
														options={{
															...defaultCustomInputOptions,
															inputData: addonIsDefaultlInput
														}}
													/>
												</MarginTopOneContainer>
												<MarginTopOneContainer>
													<CourseItemTitleContainer />
													<CustomInput
														theme={theme}
														onChange={e => {
															const isDigital = e.target.checked;
															dispatch(toggleDigitalAsset(isDigital, `initial.addons.${addonItemIndex}`));
														}}
														options={{
															...defaultCustomInputOptions,
															inputData: addonIsDigitalInput
														}}
													/>
												</MarginTopOneContainer>
												{addonIsDigitalInput.value && (
													<MarginTopOneContainer>
														<CourseItemTitleContainer />
														<CustomInput
															height={120}
															theme={theme}
															onChange={e => {
																const files = e.target.files;
																if (files && files[0]) {
																	dispatch(setDigitalAssetName(files[0], `initial.addons.${addonItemIndex}`));
																}
															}}
															options={{
																...defaultCustomInputOptions,
																genericApiService: genericService,
																inputData: addonDigitalAssetItem.productKey
															}}
														/>
													</MarginTopOneContainer>
												)}
											</Box>
											<Box
												className={classes.stripeSummaryBox}
												display="flex"
												flexDirection="column"
												mr={2}
												width={350}
											>
												<Box className="row" display="flex" flexDirection="row">
													<Typography className="row-title">Item Name</Typography>
													{addonStripeItem.isLoading.value ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">{addonStripeItem.name.value || "-"}</Typography>
													)}
												</Box>
												<Box className="row" display="flex" flexDirection="row" mt={3}>
													<Typography className="row-title">Price</Typography>
													{addonStripeItem.isLoading.value ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">
															{externalIntegrationData.currency} {addonStripeItem.price.value!.toFixed(2)}
														</Typography>
													)}
												</Box>
												<Box className="row" display="flex" flexDirection="row" mt={3}>
													<Typography className="row-title">Acutrack Product Name</Typography>
													{addonShipStationItem.isLoading.value ? (
														externalIntegrationDataRowLoader
													) : (
														<Typography className="row-content">{addonShipStationItem.name.value || "-"}</Typography>
													)}
												</Box>
											</Box>
										</Box>

										<Box width="100%" display="flex" flexDirection="row" mt={3} mb={2}>
											<Box minWidth={549} display="flex" flexDirection="column" mr={4.8}>
												<Typography className={classes.uploadedFiles}>Upload media</Typography>
												<CustomInput
													height={246}
													theme={theme}
													inputProps={{
														onChange: e => {
															if (!e.target.value) {
																dispatch(
																	setStateValue({
																		key: `userSubscriptionTypesForm.inputs.initial.addons.${addonItemIndex}.mainImageKey.imageUrl`,
																		value: ""
																	})
																);
																dispatch(
																	setStateValue({
																		key: `userSubscriptionTypesForm.inputs.initial.addons.${addonItemIndex}.mainImageUrl`,
																		value: ""
																	})
																);
															}
														},
														uploaderWidth: 549,
														fileUploaderDescription: <>Drag and Drop File Here or Browse to Choose a File</>
													}}
													options={{
														...defaultCustomInputOptions,
														genericApiService: genericService,
														inputData: { ...mainImageKey, imageUrl: mainImageUrl?.value || mainImageKey.imageUrl }
													}}
												/>
											</Box>
										</Box>
									</Box>
								);
							})}
							<Box mt={2}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => dispatch(createAddonItem(PAYMENT_CONSTANTS.INITIAL))}
									disabled={addons.length >= MAX_COURSE_ADDONS}
								>
									+ New Addon
								</Button>
							</Box>
						</Card>
						<Box mt={3}></Box>
					</>
				) : null}
				{id && stripLinkAdded ? (
					<>
						<Box mt={1}></Box>
						<Card>
							<Box>
								<Box display="flex" flexDirection="row" justifyContent="space-between">
									<Typography>Course Features</Typography>
									<Box>
										<Button
											variant="contained"
											color="primary"
											onClick={() => dispatch(saveForm("courseFeaturesForm"))}
											disabled={saveIsLoading}
										>
											Save
										</Button>
									</Box>
								</Box>
								<Box>
									<CourseFeaturesSubHeading variant="small">Additional Information</CourseFeaturesSubHeading>
									<CustomInput
										width={600}
										marginTop={1}
										theme={theme}
										options={{ ...defaultCustomInputOptions, inputData: courseFeaturesFormInputs.additionalInfo }}
									/>
								</Box>
								<Box mt={3}>
									<CourseFeaturesSubHeading variant="small">Course Features</CourseFeaturesSubHeading>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="droppable">
											{provided => (
												<Box {...provided.droppableProps} ref={provided.innerRef}>
													{memoizedMarketingPoints.map(keyItem => (
														<Draggable key={keyItem.id} draggableId={`${keyItem.id}`} index={keyItem.id}>
															{provided => (
																<DragFeatureContainer
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<CourseFeatures key={keyItem.id}>
																		<FeatureText title={keyItem.feature}>{keyItem.feature}</FeatureText>
																		<Box>
																			{" "}
																			<SvgIcon fontSize="small" cursor="grab">
																				<IconEquals />
																			</SvgIcon>{" "}
																			<DeleteFeature onClick={() => dispatch(deleteCourseFeature(keyItem.feature))} />
																		</Box>
																	</CourseFeatures>
																</DragFeatureContainer>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</Box>
											)}
										</Droppable>
									</DragDropContext>
									{isAddFeature && (
										<CustomInput
											width={600}
											theme={theme}
											onBlur={e => {
												dispatch(addCourseFeature(e.target.value));
												e.target.value && setIsAddFeature(false);
											}}
											options={{ ...defaultCustomInputOptions, inputData: courseFeaturesFormInputs.feature }}
										/>
									)}
								</Box>
							</Box>
							<Box mt={2}>
								<AddButton
									onClick={() => {
										setIsAddFeature(true);
									}}
									label={"Add Feature"}
								/>
							</Box>
						</Card>
					</>
				) : null}
			</Box>
		</Box>
	);
};

export default CourseAddEdit;
